@import '~video-react/styles/scss/video-react';
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

h1 {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.inline {
  display: inline;
}

.video-wrapper {
  position: relative;
  aspect-ratio: 16/9;
  background-color: #000;
  min-width: 20.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  width: 100%;

  & > div {
    flex: auto;
    height: auto;
    padding: inherit !important;
  }
}

video {
  aspect-ratio: 16 / 9;
}

.hoverCard {
  transition: 0.3s;
  display: block;
  &:hover {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    transform: translate(-2px, -2px);
  }
}

.plansWrapper {
  & > div {
    width: calc(25% - 12px);
  }
}

@media screen and (max-width: 1100px) {
  .plansWrapper {
    & > div {
      width: calc(50% - 8px);
    }
  }
}

@media screen and (max-width: 650px) {
  .plansWrapper {
    & > div {
      width: 100%;
    }
  }
}

/* xs: 0,
sm: 600,
md: 900,
lg: 1200,
xl: 1440, */

html {
  font-size: 16px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 15px;
  }
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
  -webkit-text-size-adjust: 100%; /* 2 */
}

* {
  //-ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(#000, 0.7) rgba(#fff, 0.2);
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(#000, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#000, 0.2);
    border-radius: 6px;
    transition: all 0.5s ease-in-out;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(#000, 0.2);
  }
}
